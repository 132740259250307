// import Cookies, { get } from 'js-cookie';
import {checkQRCodeError, checkQRCodeSuccess, checkQRCodePending} from './actions.js';

const QR_URL = `${process.env.REACT_APP_API_URL}`;

function checkQRCode(apiEndpoint = '', request = {}) {
  return (dispatch) => {
    dispatch(checkQRCodePending());
    fetch(`${QR_URL}/${apiEndpoint}`, request)
      .then((res) => {
        switch(res.status) {
          case 201:
            return res.json();
          case 200:
            return res.json();
          default:
            return dispatch(checkQRCodeError(res.status));
        }
      })
      .then((res) => {
        if (res.data) {
          if(res.data[0].scanned === true) {
            dispatch(checkQRCodeSuccess(res.status));
          }
        } else {
          dispatch(checkQRCodeError(res.errors));
        }
      })
      .catch((error) => {
        dispatch(checkQRCodeError(error));
      });
  };
}
export default checkQRCode;
