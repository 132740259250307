import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Box, Flex, Center, Text, VStack, Button} from '@chakra-ui/react';
import {getQRCodeError, getQRCode, getQRCodePending, getStampCardsToBeActivated} from '../../Services/reducers';
import {ReactComponent as Logo} from '../../Theme/Assets/Logo/Logo.svg';
import {clearQRCode as clearQRCodeAction, setStampCardToActivateExplicit} from '../../Services/actions';
import {clearQRReadStatus} from '../../Services/actions';

const Confirmation = ({...props}) => {
  const {pending, error, clearQRCode, clearQRReadStatus} = props;
  const handleClearQr = () => {
    clearQRCode();
    clearQRReadStatus();
  }
  return (
    <Flex justify="center" align="center" minHeight="100vh">
      <Button opacity="0" position="absolute" w={72} h={28} top="0" left="0" onClick={() => {handleClearQr()}}>
        Clear QR
      </Button>
      <VStack spacing="92px" direction="column-reverse">
        <Box>
          <Center>
            <Logo />
          </Center>
        </Box>
        {!pending && !error && (
            <Center mt={12}>
              <Box layerStyle="boxNotice" p={6} align="center">
                  <>
                    <Text textStyle={`caption`} color="white">
                      Kiitos asioinnistasi
                    </Text>
                    <Text mt={2} textStyle={`h3`} color="white">
                      Skannaus onnistui!
                    </Text>
                  </>
              </Box>
            </Center>
          )}
      </VStack>
    </Flex>
  );
};

const mapStateToProps = (state) => ({
  error: getQRCodeError(state),
  qrCode: getQRCode(state),
  pending: getQRCodePending(state),
  stampCards: getStampCardsToBeActivated(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearQRCode: clearQRCodeAction,
      setStampCards: setStampCardToActivateExplicit,
      clearQRReadStatus: clearQRReadStatus
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
