import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Box, Text, HStack, Button, Icon, Flex,Grid} from '@chakra-ui/react';
import {ReactComponent as CoffeeIcon} from '../../Theme/Assets/Icons/coffee.svg';
import {ReactComponent as TruckIcon} from '../../Theme/Assets/Icons/truck.svg';
import {ReactComponent as StaffIcon} from '../../Theme/Assets/Icons/staff.svg';
import {
  getRolesPending,
  getRolesError,
  getRoles,
  getLoginToken,
  getQRCode,
} from '../../Services/reducers';
import fetchRolesAction from '../../Services/fetchRoles';
import fetchQRCodeAction from '../../Services/fetchQRCode';
import Navbar from '../../Theme/Components/Navbar';
import QrCode from '../../Theme/Components/QrCode';

const selectCardIcon = (cardTitle) => {
  let icon;
  switch (cardTitle) {
    case 'Rahtarit':
      icon = TruckIcon;
      break;
    case 'Henkilökunta':
      icon = StaffIcon;
      break;
    default:
      icon = CoffeeIcon;
      break;
  }
  return  (<Icon as={icon} w={20} h={20} color="red.500" />);
};


/**
 * DATA MODEL FOR fetchQRCode -action.
 * Also used for stamps.
 */

const Subscribe = ({...props}) => {
  const {roles, error, fetchQRCode, token, fetchRoles, qr} = props;
  const [selectedId, setSelectedId] = useState('');
  const [selectedName, setSelectedName] = useState('-');

  const handleSubscription = (name, id, token) => {
    if (!name || !id || !token){ return null;}
    fetchQRCode(`roles/${id}/createActivationCode`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        title: `Aktivoi ${name}`,
        description: `aktivoi ${name} jäsenyys`,
        remove_after_activation: true
      }),
    });
    
  };

  useEffect(() => {
    fetchRoles(token);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  if (qr && (qr.id !== null)) {
    return (<QrCode />);
  }
  return (
    <Flex minHeight="100vh" p={["8","8","8","12"]} direction="column" justify="space-between">
      <Navbar />
      {!error ? (
        <>
          <Grid templateColumns={["repeat(1,1fr)","repeat(2,1fr)", "repeat(3,1fr)", "repeat(4,1fr)"]} gap={["4","4","4","6"]} autoRows>
            {!!roles.length > 0 &&
              roles
                .filter((i) =>
                  // This is for the time there's no limiting function from API
                  ['Rekka-Pekka', 'Kanta-asiakas', 'TMK'].includes(i.name),
                )
                .map((sc) => (
                  <label htmlFor={`role-${sc.id}`} key={sc.id}>
                    <Box layerStyle="box" p={6} w={56} align="center">
                      {selectCardIcon(sc.name)}
                      <Text textStyle={`h1`} mb={6} mt={2}>
                        {sc.name}
                      </Text>
                      <HStack spacing={2} justify="center">
                        <input
                          id={`role-${sc.id}`}
                          type="radio"
                          name="role"
                          onChange={(e) => {
                            setSelectedId(sc.id);
                            setSelectedName(sc.name);
                          }}
                        />
                      </HStack>
                    </Box>
                  </label>
                ))}
          </Grid>
          <HStack mt={6} justify="space-between">
            <Box>
              <Text textStyle={`small`} mb={1} color="white">
                Tekemäsi valinta:
              </Text>
              <Text textStyle={`h3`} mb={4} color="white">
                {selectedName}
              </Text>
            </Box>
            <Button type="submit" variant="solid" disabled={!selectedId} onClick={() => handleSubscription(selectedName, selectedId, token)} >
              Luo QR-koodi
            </Button>
          </HStack>
        </>
      ) : (
        <Box>
          <Text textStyle={`h3`} mb={4} color="white">
            Hups! Jtotain meni vikaan...
          </Text>
          <Text textStyle={`label`} mb={4} color="white">
            {error}
          </Text>
        </Box>
      )}
    </Flex>
  );
};
// export default Login;

const mapStateToProps = (state) => ({
  error: getRolesError(state),
  roles: getRoles(state),
  pending: getRolesPending(state),
  token: getLoginToken(state),
  qr: getQRCode(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRoles: fetchRolesAction,
      fetchQRCode: fetchQRCodeAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe);
