/*
 * !! REDUCERS ARE PURE FUNCTIONS !!
 * USE THUNK TO MAKE API CALLS OR DISPATCH ANOTHER ACTIONS IF NECESSARY
 */
import {
  FETCH_LOGIN_TOKEN_PENDING,
  FETCH_LOGIN_TOKEN_SUCCESS,
  FETCH_LOGIN_TOKEN_ERROR,
  FETCH_STAMP_CARDS_ERROR,
  FETCH_STAMP_CARDS_PENDING,
  FETCH_STAMP_CARDS_SUCCESS,
  UPDATE_STAMP_CARDS_TO_ACTIVATE,
  FETCH_ROLES_ERROR,
  FETCH_ROLES_PENDING,
  FETCH_ROLES_SUCCESS,
  FETCH_QR_CODE_ERROR,
  FETCH_QR_CODE_PENDING,
  FETCH_QR_CODE_SUCCESS,
  CLEAR_QR_CODE,
  SET_LOGGED_IN,
  CHECK_QR_CODE_SUCCESS,
  CHECK_QR_CODE_ERROR,
  CHECK_QR_CODE_PENDING,
  CLEAR_QR_READ_STATUS,
  LOGOUT
} from './actions.js';

export const initialState = {
  loginToken: {
    pending: false,
    token: null,
    error: false,
    loginStatus: null
  },
  stampCards: {
    pending: false,
    stampCards: [],
    error: false,
    toBeActivated: [],
  },
  roles: {
    pending: false,
    roles: [],
    error: false,
  },
  qrCode: {
    pending: false,
    qrCode: null,
    error: false
  },
  loggedIn: false,
  qrStatus: {
    read: false,
    error: null,
  },
};

export function loggedIn(state = initialState.loggedIn, action) {
  switch (action.type) {
    case SET_LOGGED_IN:
      return {
        ...state,
        loggedIn: action.payload
      }
      default:
        return state;
  }
}

export function loginToken(state = initialState.loginToken, action) {
  switch (action.type) {
    case FETCH_LOGIN_TOKEN_PENDING:
      return {
        ...state,
        pending: true,
        error: null
      };
    case FETCH_LOGIN_TOKEN_SUCCESS:
      return {
        ...state,
        pending: false,
        token: action.payload.token,
      };
    case FETCH_LOGIN_TOKEN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        token: null
      };
    case LOGOUT:
      return {
        ...state,
        pending: false,
        error: false,
        token: null
      }
    default:
      return state;
  }
}

export function qrCode(state = initialState.qrCode, action) {
  switch (action.type) {
    case FETCH_QR_CODE_PENDING:
      return {
        ...state,
        pending: true,
        error: null
      };
    case FETCH_QR_CODE_SUCCESS:
      return {
        ...state,
        pending: false,
        qrCode: action.payload.code,
      };
    case FETCH_QR_CODE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        qrCode: null
      };
    case CLEAR_QR_CODE:
      return {
        ...state,
        pending: false,
        error: false,
        qrCode: null,
      };
    default:
      return state;
  }
}

export function stampCards(state = initialState.stampCards, action) {
  switch (action.type) {
    case FETCH_STAMP_CARDS_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case FETCH_STAMP_CARDS_SUCCESS:
      return {
        ...state,
        pending: false,
        stampCards: action.payload.stampCards,
        toBeActivated: action.payload.toBeActivated,
        error: action.payload.error
      };
    case FETCH_STAMP_CARDS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        stampCards: []
      };
    case UPDATE_STAMP_CARDS_TO_ACTIVATE:
      return {
        ...state,
        toBeActivated: action.payload.toBeActivated
      }
    default:
      return state;
  }
}

export function roles(state = initialState.roles, action) {
  switch (action.type) {
    case FETCH_ROLES_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case FETCH_ROLES_SUCCESS:
      return {
        ...state,
        pending: false,
        roles: action.payload.roles,
        error: action.payload.error
      };
    case FETCH_ROLES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        roles: []
      };
    default:
      return state;
  }
}

export function qrStatus(state = initialState.qrStatus, action) {
  switch (action.type) {
    case CHECK_QR_CODE_SUCCESS:
      return {
        ...state,
        read: true,
        pending: false,
      };
    case CHECK_QR_CODE_ERROR:
      return {
        ...state,
        read: false,
        pending: false,
        error: action.payload.error,
      };
    case CHECK_QR_CODE_PENDING:
      return {
        ...state,
        pending: true,
        error: null
      };
    case CLEAR_QR_READ_STATUS:
      return {
        ...state,
        read: false,
        error: null
      };
    default:
      return state;
  }
}

export const getLoginToken = (state) => state.loginToken.token;
export const getLoginTokenPending = (state) => state.loginToken.pending;
export const getLoginTokenError = (state) => state.loginToken.error;
export const getStampCards = (state) => state.stampCards.stampCards;
export const getStampCardsPending = (state) => state.stampCards.pending;
export const getStampCardsToBeActivated = (state) => state.stampCards.toBeActivated;
export const getStampCardsError = (state) => state.stampCards.error;
export const getRoles = (state) => state.roles.roles;
export const getRolesPending = (state) => state.roles.pending;
export const getRolesError = (state) => state.roles.error;
export const getQRCode = (state) => state.qrCode.qrCode;
export const getQRCodePending = (state) => state.qrCode.pending;
export const getQRCodeError = (state) => state.qrCode.error;
export const getLoggedIn = (state) => state.loggedIn;
export const getQRStatus = (state) => state.qrStatus.read;
export const getQRCodeId = (state) => state.qrCode.qrCode.stamp_card_scan_ids !== undefined ? state.qrCode.qrCode.stamp_card_scan_ids[0] : '';
