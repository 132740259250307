import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Box, Flex, Center, Text, Stack, Button, Img} from '@chakra-ui/react';
import {getQRCodeError, getQRCode, getQRCodePending, getStampCardsToBeActivated, getQRStatus, getLoginToken, getQRCodeId} from '../../Services/reducers';
import {ReactComponent as Logo} from '../../Theme/Assets/Logo/Logo.svg';
import {clearQRCode as clearQRCodeAction, setStampCardToActivateExplicit} from '../../Services/actions';
import checkQRCode from 'Services/checkQRCodeStatus';
import {clearQRReadStatus} from '../../Services/actions';

import Confirmation from './Confirmation';

const QRCode = ({...props}) => {
  const {pending, error, qrCode, clearQRCode, stampCards, setStampCards, token, checkQRCode, qrStatus, qrId } = props;
  const handleClearQr = () => {
    setStampCards(stampCards, -1, 0);
    clearQRCode();
    clearQRReadStatus();
  }

  const checkQRCodeReadStatus = (token = '', id = qrId) => {
    if(!qrCode.role) {
      checkQRCode(`stampCardScans/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkQRCodeReadStatus(token);
    }, 5000);
    return () => clearInterval(interval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (qrStatus && qrStatus.read !== false) {
    return <Confirmation />;
  }

  return (
    <Flex justify="center" align="center" minHeight="100vh">
      <Button opacity="0" position="absolute" w={52} h={28} top="0" left="0" onClick={() => {handleClearQr()}}>
        Clear QR
      </Button>
      <Stack spacing={["0","0","0","92px"]} direction={["column", "column", "column", "row"]} align="center">
        <Box mb="12">
          <Center>
            <Logo />
          </Center>
        </Box>
        <Stack direction="column" align="center">
          <Box mb="6">
            {!pending && !error && (
              <Box>
                <Box layerStyle="box" textAlign="center" mb={2}>
                  <Img src={qrCode.qr_code} alt="" h="100%" p={6} w={64} />
                </Box>
                {!!qrCode.activation_code && (
                  <Box layerStyle="box" p={6} textAlign="center">
                    <Text textStyle={`h1`}>{qrCode.activation_code}</Text>
                  </Box>
                )}
              </Box>
            )}
          </Box>
          {!pending && !error && (
              <Center mt={12}>
                <Box layerStyle="boxNotice" p={6} align="center">
                  {!!qrCode.description && (
                    <>
                      <Text textStyle={`caption`} color="white">
                        Skannaa tai aktivoi koodi sovelluksella ja...
                      </Text>
                      <Text mt={2} textStyle={`h3`} color="white">
                        {qrCode.description}
                      </Text>
                    </>
                  )}
                  {!qrCode.description && (
                    <Text mt={2} textStyle={`h3`} color="white">
                      Skannaa koodi sovelluksella ja lunasta etusi!
                    </Text>
                  )}
                </Box>
              </Center>
            )}
        </Stack>
      </Stack>
    </Flex>
  );
};

const mapStateToProps = (state) => ({
  error: getQRCodeError(state),
  qrCode: getQRCode(state),
  pending: getQRCodePending(state),
  token: getLoginToken(state),
  stampCards: getStampCardsToBeActivated(state),
  qrStatus: getQRStatus(state),
  qrId: getQRCodeId(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearQRCode: clearQRCodeAction,
      setStampCards: setStampCardToActivateExplicit,
      checkQRCode: checkQRCode,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(QRCode);
