import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import fetchLoginTokenAction from '../../Services/fetchLoginToken';
import {
  Box,
  Flex,
  Center,
  Text,
  Stack,
  Button,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import {Formik, Form, Field} from 'formik';
import {
  getLoginTokenError,
  getLoginToken,
  getLoginTokenPending,
} from '../../Services/reducers';
import {ReactComponent as Logo} from '../../Theme/Assets/Logo/Logo.svg';

const Login = ({...props}) => {
  const {pending, fetchLoginToken, token} = props;
  if (!pending && token !== null) {
    window.location = '/stamps';
  }
  return (
    <Flex justify="center" align="center" minHeight="100vh">
      <Stack direction={["column", "column", "column", "row"]} align="center" spacing="92px">
        <Box>
          <Center>
            <Logo />
          </Center>
        </Box>
        <Box>
          <Box layerStyle="box" p={6}>
            <Text textStyle={`h1`} mb={4} textAlign="center">
              Kirjaudu sisään
            </Text>
            <Formik
              initialValues={{email: '', password: ''}}
              onSubmit={(values) => {
                fetchLoginToken(values);
              }}
            >
              {() => (
                <Form>
                  <Field name="email">
                    {({field, form}) => (
                      <FormControl id="email" mb={4}>
                        <FormLabel textStyle={`label`} color="gray.600" textAlign="center">
                          Sähköposti
                        </FormLabel>
                        <Input {...field} id="email" placeholder="Sähköposti" />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="password">
                    {({field, form}) => (
                      <FormControl id="password" mb={4}>
                        <FormLabel textStyle={`label`} color="gray.600" textAlign="center">
                          Salasana
                        </FormLabel>
                        <Input
                          {...field}
                          type="password"
                          id="password"
                          placeholder="salasana"
                        />
                      </FormControl>
                    )}
                  </Field>
                    <Center>
                      <Button
                        type="submit"
                        variant="primary"
                        isLoading={pending}
                        order={{base: 1, md: 2}}
                      >
                      Kirjaudu sisään
                    </Button>
                  </Center>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Stack>
    </Flex>
  );
};

const mapStateToProps = (state) => ({
  error: getLoginTokenError(state),
  token: getLoginToken(state),
  pending: getLoginTokenPending(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchLoginToken: fetchLoginTokenAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
