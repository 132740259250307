import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Box, Text, HStack, Button, IconButton, Input, Icon, Flex, Grid} from '@chakra-ui/react';
import {FiPlus, FiMinus} from 'react-icons/fi';
import {ReactComponent as CoffeeIcon} from '../../Theme/Assets/Icons/coffee.svg';
import {ReactComponent as FoodIcon} from '../../Theme/Assets/Icons/food.svg';
import {ReactComponent as TruckIcon} from '../../Theme/Assets/Icons/truck.svg';
import {ReactComponent as StaffIcon} from '../../Theme/Assets/Icons/staff.svg';
import {
  getStampCardsPending,
  getStampCardsError,
  getStampCards,
  getLoginToken,
  getQRCode,
  getStampCardsToBeActivated,
} from '../../Services/reducers';
import {
  decrementStampCardToActivate,
  incrementStampCardToActivate,
  setStampCardToActivateExplicit,
} from '../../Services/actions';
import fetchStampCardsAction from '../../Services/fetchStampCards';
import fetchQRCodeAction from '../../Services/fetchQRCode';

import Navbar from '../../Theme/Components/Navbar';
import QrCode from '../../Theme/Components/QrCode';

const selectCardIcon = (cardTitle) => {
  let icon;
  switch (cardTitle) {
    case 'Kahvipassi':
      icon = CoffeeIcon;
      break;
    case 'Pesupassi':
      icon = TruckIcon;
      break;
    case 'Ateriapassi':
      icon = FoodIcon;
      break;
    case 'Jäätelöpassi':
      icon = StaffIcon;
      break;
    default:
      icon = StaffIcon;
      break;
  }
  return <Icon as={icon} w={20} h={20} color="red.500" />;
};

const findQuantity = (obj) => {
  if (obj.quantity) {
    return obj.quantity;
  }
  return 0;
};

const Main = ({...props}) => {
  const {
    stampCards,
    fetchStampCards,
    toBeActivated,
    setToActivate,
    incrementToActivate,
    decrementToActivate,
    error,
    fetchQRCode,
    token,
    qr,
  } = props;

  const handleStamping = (token = '') => {
    if (toBeActivated.filter((i) => i.quantity < 1).length < 1 || !token) {
      return null;
    }
    fetchQRCode(`stampCards/createStampCardScans`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        stampCardScans: [...toBeActivated].filter((i) => i.quantity >= 1),
      }),
    });
  };

  useEffect(() => {
    fetchStampCards(token);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (qr && qr.id !== null) {
    return <QrCode />;
  }
  return (
    <Flex minHeight="100vh" p={["8","8","8","12"]} direction="column" justify="space-between">
      <Navbar />
      {!error ? (
        <>
          <Grid templateColumns={["repeat(1,1fr)","repeat(2,1fr)", "repeat(3,1fr)", "repeat(4,1fr)"]} gap={["4","4","4","6"]} autoRows>
            {!!stampCards.length > 0 &&
              stampCards.map((sc) => {
                return (
                  <Box
                    key={sc.id}
                    data-target={sc.id}
                    layerStyle="box"
                    p={4}
                    align="center"
                  >
                    {selectCardIcon(sc.title)}
                    <Text textStyle={`h1`} mb={2}>
                      {sc.title}
                    </Text>
                    <HStack spacing={2} justify="center">
                      {[1, 2, 3].map((b) => (
                        <Button
                          key={b}
                          variant="primary"
                          onClick={() => setToActivate([...toBeActivated], sc.id, b)}
                        >
                          {b}
                        </Button>
                      ))}
                    </HStack>
                    <HStack spacing={2} my={2} justify="center">
                      <IconButton
                        onClick={() => {
                          decrementToActivate([...toBeActivated], sc.id);
                        }}
                        aria-label="Minus"
                        variant="outline"
                        icon={<FiMinus />}
                        h={12}
                        w={12}
                      />
                      <Input
                        type="number"
                        readOnly
                        variant="outline"
                        placeholder="Outline"
                        value={findQuantity(
                          [...toBeActivated].find((i) => i.id === sc.id),
                        )}
                        h={12}
                      />
                      <IconButton
                        onClick={() => {
                          incrementToActivate([...toBeActivated], sc.id);
                        }}
                        aria-label="Plus"
                        variant="outline"
                        colorScheme="gray"
                        icon={<FiPlus />}
                        h={12}
                        w={12}
                      />
                    </HStack>
                  </Box>
                );
              })}
          </Grid>
          <HStack mt={6} justify="space-between">
            <Box>
              {/*
              <Text textStyle={`small`} mb={1} color="white">
                Tekemäsi valinta:
              </Text>
              <Text textStyle={`h3`} mb={4} color="white">
                {formToBeActivatedString([...toBeActivated].filter(i => (i.quantity >= 1)))}
              </Text>
              */}
            </Box>
            <Button type="submit" variant="solid" onClick={() => handleStamping(token)}>
              Luo QR-koodi
            </Button>
          </HStack>
        </>
      ) : (
        <p>Error!</p>
      )}
    </Flex>
  );
};
// export default Login;

const mapStateToProps = (state) => ({
  error: getStampCardsError(state),
  stampCards: getStampCards(state),
  pending: getStampCardsPending(state),
  toBeActivated: getStampCardsToBeActivated(state),
  token: getLoginToken(state),
  qr: getQRCode(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchStampCards: fetchStampCardsAction,
      fetchQRCode: fetchQRCodeAction,
      setToActivate: setStampCardToActivateExplicit,
      incrementToActivate: incrementStampCardToActivate,
      decrementToActivate: decrementStampCardToActivate,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Main);
