import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import {loggedIn, loginToken, stampCards, qrCode, roles, qrStatus} from './reducers';

const rootReducer = combineReducers({
  loggedIn,
  qrCode,
  loginToken,
  stampCards,
  roles,
  qrStatus,
});

const configureStore = () => {
  return createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
};

export default configureStore;
