/**
 * LOGIN TOKEN
 */
export const FETCH_LOGIN_TOKEN_PENDING = 'FETCH_LOGIN_TOKEN_PENDING';
export const FETCH_LOGIN_TOKEN_SUCCESS = 'FETCH_LOGIN_TOKEN_SUCCESS';
export const FETCH_LOGIN_TOKEN_ERROR = 'FETCH_LOGIN_TOKEN_ERROR';

export function fetchLoginTokenPending() {
  return {
    type: FETCH_LOGIN_TOKEN_PENDING,
  };
}

export function fetchLoginTokenSuccess(token) {
  return {
    type: FETCH_LOGIN_TOKEN_SUCCESS,
    payload: {token},
  };
}

export function fetchLoginTokenError(error) {
  return {
    type: FETCH_LOGIN_TOKEN_ERROR,
    payload: {error},
  };
}
export function setLoginTokenFromCookie(token) {
  return {
    type: FETCH_LOGIN_TOKEN_SUCCESS,
    payload: {token},
  };
}
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export function setLoggedIn(bool) {
  return {
    type: SET_LOGGED_IN,
    payload: bool,
  };
}

export const LOGOUT = 'LOGOUT';
export function logout() {
  return {
    type: LOGOUT,
    payload: false,
  };
}

/**
 * QR CODE
 */
export const FETCH_QR_CODE_PENDING = 'FETCH_QR_CODE_PENDING';
export const FETCH_QR_CODE_SUCCESS = 'FETCH_QR_CODE_SUCCESS';
export const FETCH_QR_CODE_ERROR = 'FETCH_QR_CODE_ERROR';
export const CLEAR_QR_CODE = 'CLEAR_QR_CODE';
export const CHECK_QR_CODE_SUCCESS = 'CHECK_QR_CODE_SUCCESS';
export const CHECK_QR_CODE_ERROR = 'CHECK_QR_CODE_ERROR';
export const CHECK_QR_CODE_PENDING = 'CHECK_QR_CODE_PENDING';
export const CLEAR_QR_READ_STATUS = 'CLEAR_QR_READ_STATUS';

export function fetchQRCodeError(error) {
  return {
    type: FETCH_QR_CODE_ERROR,
    payload: {error},
  };
}
export function fetchQRCodeSuccess(code) {
  return {
    type: FETCH_QR_CODE_SUCCESS,
    payload: {code},
  };
}
export function fetchQRCodePending() {
  return {
    type: FETCH_QR_CODE_PENDING,
  };
}

export function clearQRCode() {
  return {
    type: CLEAR_QR_CODE,
  };
}

export function checkQRCodeSuccess(status) {
  return {
    type: CHECK_QR_CODE_SUCCESS,
    payload: {status},
  };
}

export function checkQRCodeError(error) {
  return {
    type: CHECK_QR_CODE_ERROR,
    payload: {error},
  };
}
export function checkQRCodePending() {
  return {
    type: CHECK_QR_CODE_PENDING,
  };
}

export function clearQRReadStatus() {
  return {
    type: CLEAR_QR_READ_STATUS,
  };
}


/**
 * STAMP CARDS
 */
export const FETCH_STAMP_CARDS_PENDING = 'FETCH_STAMP_CARDS_PENDING';
export const FETCH_STAMP_CARDS_SUCCESS = 'FETCH_STAMP_CARDS_SUCCESS';
export const FETCH_STAMP_CARDS_ERROR = 'FETCH_STAMP_CARDS_ERROR';
export const UPDATE_STAMP_CARDS_TO_ACTIVATE = 'UPDATE_STAMP_CARDS_TO_ACTIVATE';

export function fetchStampCardsPending() {
  return {
    type: FETCH_STAMP_CARDS_PENDING,
  };
}

export function fetchStampCardsSuccess(stampCards) {
  const toBeActivated = stampCards.map((sc) => ({id: sc.id, title: sc.title, quantity: 0}));
  return {
    type: FETCH_STAMP_CARDS_SUCCESS,
    payload: {stampCards, error: false, toBeActivated},
  };
}

export function fetchStampCardsError(error) {
  return {
    type: FETCH_STAMP_CARDS_ERROR,
    payload: error,
  };
}

export function incrementStampCardToActivate(prevState, stampCardId) {
  const toBeActivated = prevState.filter((i) => {
    if (i.id === stampCardId) {
      i.quantity = i.quantity > 9 ? i.quantity : i.quantity + 1;
    }
    return i;
  });
  return {
    type: UPDATE_STAMP_CARDS_TO_ACTIVATE,
    payload: {toBeActivated},
  };
}
export function decrementStampCardToActivate(prevState, stampCardId) {
  const toBeActivated = prevState.filter((i) => {
    if (i.id === stampCardId) {
      i.quantity = i.quantity < 1 ? i.quantity : i.quantity - 1;
    }
    return i;
  });
  return {
    type: UPDATE_STAMP_CARDS_TO_ACTIVATE,
    payload: {toBeActivated},
  };
}
export function setStampCardToActivateExplicit(prevState, stampCardId, amount) {
  const toBeActivated = prevState.filter((i) => {
    if (stampCardId === -1) {
        i.quantity = amount;
    }else {
      if (i.id === stampCardId) {
        i.quantity = amount;
      }
    }
    return i;
  });
  return {
    type: UPDATE_STAMP_CARDS_TO_ACTIVATE,
    payload: {toBeActivated},
  };
}

/**
 * ROLES
 */
export const FETCH_ROLES_PENDING = 'FETCH_ROLES_PENDING';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_ERROR = 'FETCH_ROLES_ERROR';

export function fetchRolesPending() {
  return {
    type: FETCH_ROLES_PENDING,
  };
}

export function fetchRolesSuccess(roles) {
  return {
    type: FETCH_ROLES_SUCCESS,
    payload: {roles, error: false},
  };
}

export function fetchRolesError(error) {
  return {
    type: FETCH_ROLES_ERROR,
    payload: {error},
  };
}
